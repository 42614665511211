export const menuItems = [
  {
    name: 'Brazilian Mojito',
    description:
      'Fresh lime, mint and sugar muddled together, mixed with your choice of cachaça, and topped off with club soda (232 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cachaca Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'The Rio Cosmo',
    description:
      'Fresh lime, triple sec, cranberry juice and a splash of simple syrup shaken with your choice of cachaça, served in a frosted martini glass (351 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cachaca Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Ipanema Margarita',
    description:
      'Fresh lime juice, triple sec, sour mix, and a splash of Cointreau all shaken with your choice of cachaça (415 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cachaca Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Rio Sunrise',
    description:
      'A blend of your choice of cachaça, peach schnapps, pineapple and cherry juice served shaken in a high ball glass (279 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cachaca Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Brazilian Rose',
    description:
      'Passion fruit juice, a splash of Grand Marnier and your choice of cachaça shaken with ice and strained into a chilled martini glass (312 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cachaca Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Carnaval',
    description:
      'Pomegranate liqueur mixed with lime juice, sour mix, and your choice of cachaça, served with a splash of orange juice (275 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cachaca Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'The Samba',
    description:
      'A refreshing lemonade style drink: a combination of lemonade, lemon-lime soda, a touch of peach schnapps, fresh lemon & lime pieces, spiked with your choice of cachaça (330 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cachaca Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Bahia Breeze',
    description:
      'A tropical combination of pineapple juice and coconut cream, shaken with your choice of cachaça (249 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cachaca Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Q Ginger Crush',
    description:
      'P51 Cachaça mixed with fresh lime juice, Q ginger beer, and a touch of sweetness (250 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Mezcalinha',
    description:
      'Xicala, Mezcal Joven, fresh limes, mango puree, kissed with Habanero syrup muddled served on the rocks. (289 calories)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: "Tito's Caipiroska",
    description:
      'Tito’s Handmade vodka, fresh limes, sugar, muddled then shaken (232 calories)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Santo Passion Fruit Margarita',
    description:
      'Santo Blanco Tequila, orange liqueur, sour mix and passion fruit puree, shaken served on the rocks (406 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Brazilian Kiss',
    description:
      'Absolut Raspberri vodka, Cointreau, Chambord, a touch of fresh squeezed lime and garnished with a lime wheel (219 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Deep Eddy Lemon Drop',
    description:
      ' Deep Eddy lemon vodka, citrus liquor, lemon juice and sour mix, served in a frosted sugar-rimmed glass and topped with a lemon twist (453 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Jack Daniel’s Apple Martini',
    description:
      'Jack Daniel’s Tennessee whiskey and sour apple liqueur, served in a caramel-rimmed glass (278 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Drumshanbo Gunpowder Strawberry Limeade ',
    description: 'Gunpowder gin, strawberry puree and fresh limes (174 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: '1792 Manhattan',
    description:
      '1792 Small Batch bourbon with a splash of sweet vermouth and bitters, served up or on the rocks (285 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Cocktails',
    section: 'Cocktails',
    sub_section: '',
    action: ''
  },
  {
    name: 'Caposaldo, Prosecco, Italy',
    description: '',
    price: '12',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Sparkling',
    sub_section: '',
    action: ''
  },
  {
    name: 'Bollini, Pinot Grigio, Trentino, Italy',
    description: '',
    price: '12',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'White',
    sub_section: '',
    action: ''
  },
  {
    name: 'Texas de Brazil, Private Label, Sauvignon Blanc, Uco Valley, Argentina',
    description: '',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'White',
    sub_section: '',
    action: ''
  },
  {
    name: 'Texas de Brazil, Private Label, Chardonnay, Uco Valley, Argentina',
    description: '',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'White',
    sub_section: '',
    action: ''
  },
  {
    name: 'Saint Francis, Sauvignon Blanc, Sonoma County, California',
    description: '',
    price: '14',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'White',
    sub_section: '',
    action: ''
  },
  {
    name: 'Sonoma-Cutrer, Chardonnay, Sonoma Coast, California',
    description: '',
    price: '15',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'White',
    sub_section: '',
    action: ''
  },
  {
    name: 'Conundrum, White Blend, California',
    description: '',
    price: '14',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'White',
    sub_section: '',
    action: ''
  },
  {
    name: 'Texas de Brazil, Private Label, Sweet White, San Juan, Argentina',
    description: '',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'White',
    sub_section: '',
    action: ''
  },
  {
    name: 'DAOU, Rosé, Paso Robles, California',
    description: '',
    price: '14',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'White',
    sub_section: '',
    action: ''
  },
  {
    name: 'Texas de Brazil, Private Label, Pinot Noir, Uco Valley, Argentina',
    description: '',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'Meiomi, Pinot Noir, Sonoma Coast, California',
    description: '',
    price: '15',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'Portillo, Malbec, Mendoza, Uco Valley, Argentina',
    description: '',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'Texas de Brazil, Private Label, Merlot, Uco Valley, Argentina',
    description: '',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'Kunde, Merlot, Sonoma Valley, California',
    description: '',
    price: '15',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'Texas de Brazil, Private Label, Cabernet Sauvignon, Uco Valley, Argentina',
    description: '',
    price: '12',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'Texas de Brazil, Private Label, Red Blend, San Juan, Argentina',
    description: '',
    price: '12',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'Conundrum, Red Blend, California',
    description: '',
    price: '14',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'Uppercut, Cabernet Sauvignon, California',
    description: '',
    price: '14',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'DAOU, The Pessimist, Red Blend, Paso Robles, California',
    description: '',
    price: '14',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'DAOU, Cabernet Sauvignon, Paso Robles, California',
    description: '',
    price: '16',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'Texas de Brazil, Signature Red Blend',
    description: '(Glass, 217 cal) & (Pitcher, 868 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Sangría',
    sub_section: 'Sangría',
    action: ''
  },
  {
    name: 'Texas de Brazil, Signature Red Blend',
    description: '(Glass, 217 cal) & (Pitcher, 868 cal)',
    price: '44',
    vintage: '',
    inventory_id: '',
    category: 'Wines by the Glass',
    section: 'Sangría',
    sub_section: 'Sangría',
    action: ''
  },
  {
    name: 'Piper-Heidsieck, Brut, Ŕeims',
    description: '',
    price: '135',
    vintage: 'NV',
    inventory_id: '7107',
    category: 'Champagne & Sparkling',
    section: 'Champagne',
    sub_section: '',
    action: ''
  },
  {
    name: 'Piper-Heidsieck, Rosé Sauvage, Ŕeims',
    description: '',
    price: '164',
    vintage: 'NV',
    inventory_id: '7108',
    category: 'Champagne & Sparkling',
    section: 'Champagne',
    sub_section: '',
    action: ''
  },
  {
    name: 'Dom Pérignon, Épernay',
    description: '',
    price: '455',
    vintage: '2012',
    inventory_id: '7120',
    category: 'Champagne & Sparkling',
    section: 'Champagne',
    sub_section: '',
    action: ''
  },
  {
    name: 'Louis Roederer, Cristal, Reims',
    description: '',
    price: '555',
    vintage: '2009',
    inventory_id: '7124',
    category: 'Champagne & Sparkling',
    section: 'Champagne',
    sub_section: '',
    action: ''
  },
  {
    name: 'Caposaldo, Prosecco, Italy',
    description: '',
    price: '48',
    vintage: 'NV',
    inventory_id: '7174',
    category: 'Champagne & Sparkling',
    section: 'Sparkling',
    sub_section: '',
    action: ''
  },
  {
    name: 'Domaine Carneros by Taittinger, Brut Cuvée, Carneros, California',
    description: '',
    price: '83',
    vintage: '2014',
    inventory_id: '7178',
    category: 'Champagne & Sparkling',
    section: 'Sparkling',
    sub_section: '',
    action: ''
  },
  {
    name: 'Sonoma-Cutrer, Chardonnay, Sonoma Coast, California',
    description: '',
    price: '60',
    vintage: '2021',
    inventory_id: '7202',
    category: 'White Wine',
    section: 'United States',
    sub_section: 'Chardonnay',
    action: ''
  },
  {
    name: 'Robert Mondavi, Napa Valley',
    description: '',
    price: '53',
    vintage: '2015',
    inventory_id: '7210',
    category: 'White Wine',
    section: 'United States',
    sub_section: 'Chardonnay',
    action: ''
  },
  {
    name: 'Cakebread, Napa Valley',
    description: '',
    price: '113',
    vintage: '2017',
    inventory_id: '7212',
    category: 'White Wine',
    section: 'United States',
    sub_section: 'Chardonnay',
    action: ''
  },
  {
    name: 'Benziger,Sonoma County',
    description: '',
    price: '46',
    vintage: '2020',
    inventory_id: '7214',
    category: 'White Wine',
    section: 'United States',
    sub_section: 'Chardonnay',
    action: ''
  },
  {
    name: 'DAOU, Paso Robles',
    description: '',
    price: '50',
    vintage: '2021',
    inventory_id: '7220',
    category: 'White Wine',
    section: 'United States',
    sub_section: 'Chardonnay',
    action: ''
  },
  {
    name: 'Emmolo, Napa Valley',
    description: '',
    price: '57',
    vintage: '2016',
    inventory_id: '7281',
    category: 'White Wine',
    section: 'United States',
    sub_section: 'Sauvignon Blanc',
    action: ''
  },
  {
    name: 'St. Francis, Sonoma County',
    description: '',
    price: '56',
    vintage: '2021',
    inventory_id: '7284',
    category: 'White Wine',
    section: 'United States',
    sub_section: 'Sauvignon Blanc',
    action: ''
  },
  {
    name: 'Conundrum, White Blend, California',
    description: '',
    price: '52',
    vintage: '2016',
    inventory_id: '7292',
    category: 'White Wine',
    section: 'United States',
    sub_section: 'Alternative Whites',
    action: ''
  },
  {
    name: 'Texas de Brazil, Private Label, Uco Valley, Argentina',
    description: '',
    price: '44',
    vintage: '2021',
    inventory_id: '7300',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Chardonnay',
    action: ''
  },
  {
    name: 'Bodegas Salentein, Reserve, Uco Valley, Argentina',
    description: '',
    price: '47',
    vintage: '2016',
    inventory_id: '7305',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Chardonnay',
    action: ''
  },
  {
    name: 'Louis Jadot, Pouilly-Fuissé, Burgundy, France',
    description: '',
    price: '80',
    vintage: '2017',
    inventory_id: '7312',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Chardonnay',
    action: ''
  },
  {
    name: 'Texas de Brazil, Private Label, Uco Valley, Argentina',
    description: '',
    price: '44',
    vintage: '2021',
    inventory_id: '7320',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Sauvignon Blanc',
    action: ''
  },
  {
    name: '13 Celsius, Marlborough, New Zealand',
    description: '',
    price: '50',
    vintage: '2019',
    inventory_id: '7325',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Sauvignon Blanc',
    action: ''
  },
  {
    name: 'Kim Crawford, Marlborough, New Zealand',
    description: '',
    price: '56',
    vintage: '2021',
    inventory_id: '7326',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Sauvignon Blanc',
    action: ''
  },
  {
    name: 'Bollini, Trentino, Italy',
    description: '',
    price: '48',
    vintage: '2021',
    inventory_id: '7330',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Pinot Grigio',
    action: ''
  },
  {
    name: 'Santa Margherita, Alto Aldige',
    description: '',
    price: '70',
    vintage: '2018',
    inventory_id: '7332',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Pinot Grigio',
    action: ''
  },
  {
    name: 'Alta Luna, Trentino, Italy',
    description: '',
    price: '45',
    vintage: '2018',
    inventory_id: '7336',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Pinot Grigio',
    action: ''
  },
  {
    name: 'Jean-Luc Colombo, Cape Bleue, Rosé, Provence, France',
    description: '',
    price: '43',
    vintage: '2021',
    inventory_id: '7402',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Rosé',
    action: ''
  },
  {
    name: 'DAOU, Rosé, Paso Robles, California',
    description: '',
    price: '56',
    vintage: '2021',
    inventory_id: '7406',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Rosé',
    action: ''
  },
  {
    name: 'Texas de Brazil, Private Label, Sweet White, San Juan, Argentina',
    description: '',
    price: '44',
    vintage: '2021',
    inventory_id: '7340',
    category: 'White Wine',
    section: 'International',
    sub_section: 'Alternative Whites',
    action: ''
  },
  {
    name: 'Tribute by Benziger',
    description: '',
    price: '55',
    vintage: '2018',
    inventory_id: '7492',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'UpperCut, California',
    description: '',
    price: '56',
    vintage: '2019',
    inventory_id: '7494',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Beringer, Knights Valley',
    description: '',
    price: '63',
    vintage: '2019',
    inventory_id: '7497',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Beaulieu Vineyard, Rutherford',
    description: '',
    price: '77',
    vintage: '2018',
    inventory_id: '7498',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Silver Oak, Alexander Valley',
    description: '',
    price: '187',
    vintage: '2014',
    inventory_id: '7508',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Cakebread, Napa Valley',
    description: '',
    price: '204',
    vintage: '2016',
    inventory_id: '7512',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Caymus, Napa Valley ',
    description: '',
    price: '210',
    vintage: '2016',
    inventory_id: '7516',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Caymus, Special Selection',
    description: '',
    price: '267',
    vintage: '2015',
    inventory_id: '7517',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Heritance, Napa Valley',
    description: '',
    price: '80',
    vintage: '2016',
    inventory_id: '7518',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Robert Mondavi, Napa Valley',
    description: '',
    price: '77',
    vintage: '2019',
    inventory_id: '7524',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Robert Mondavi, Reserve, Napa Valley ',
    description: '',
    price: '231',
    vintage: '2015',
    inventory_id: '7525',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Sequoia Grove, Napa Valley',
    description: '',
    price: '123',
    vintage: '2016',
    inventory_id: '7526',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Silver Oak, Napa Valley',
    description: '',
    price: '250',
    vintage: '2014',
    inventory_id: '7528',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: "Stag's Leap Winery, Napa Valley",
    description: '',
    price: '111',
    vintage: '2019',
    inventory_id: '7513',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'DAOU, Paso Robles',
    description: '',
    price: '66',
    vintage: '2021',
    inventory_id: '7532',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'DAOU, Reserve, Paso Robles',
    description: '',
    price: '140',
    vintage: '2019',
    inventory_id: '7534',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'DAOU, Soul of a Lion, Paso Robles',
    description: '',
    price: '205',
    vintage: '2016',
    inventory_id: '7536',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Kunde, Sonoma Valley',
    description: '',
    price: '64',
    vintage: '2019',
    inventory_id: '7600',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Merlot',
    action: ''
  },
  {
    name: 'St. Francis, Sonoma County',
    description: '',
    price: '63',
    vintage: '2019',
    inventory_id: '7602',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Merlot',
    action: ''
  },
  {
    name: 'Emmolo, Napa Valley',
    description: '',
    price: '124',
    vintage: '2016',
    inventory_id: '7605',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Merlot',
    action: ''
  },
  {
    name: 'Provenance, Napa Valley',
    description: '',
    price: '61',
    vintage: '2017',
    inventory_id: '7609',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Merlot',
    action: ''
  },
  {
    name: 'Imagery, California',
    description: '',
    price: '53',
    vintage: '2021',
    inventory_id: '7724',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Pinot Noir',
    action: ''
  },
  {
    name: 'Meiomi, Sonoma Coast, California',
    description: '',
    price: '60',
    vintage: '2021',
    inventory_id: '7728',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Pinot Noir',
    action: ''
  },
  {
    name: 'Lyric by Etude, Santa Barbara County',
    description: '',
    price: '55',
    vintage: '2018',
    inventory_id: '7729',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Pinot Noir',
    action: ''
  },
  {
    name: 'Belle Glos, Clark & Telephone Vineyards, Santa Maria Valley, California',
    description: '',
    price: '150',
    vintage: '2017',
    inventory_id: '7730',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Pinot Noir',
    action: ''
  },
  {
    name: 'Au Contraire, Sonoma Coast, California',
    description: '',
    price: '60',
    vintage: '2014',
    inventory_id: '7735',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Pinot Noir',
    action: ''
  },
  {
    name: 'The Four Graces, Willamette Valley, Oregon',
    description: '',
    price: '73',
    vintage: '2021',
    inventory_id: '7742',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Pinot Noir',
    action: ''
  },
  {
    name: 'Conundrum, Red Blend, California',
    description: '',
    price: '56',
    vintage: '2020',
    inventory_id: '7854',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Meritage & Proprietary Blends',
    action: ''
  },
  {
    name: 'Chalk Hill Estate, Red, Sonoma County',
    description: '',
    price: '174',
    vintage: '2015',
    inventory_id: '7858',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Meritage & Proprietary Blends',
    action: ''
  },
  {
    name: 'Roth Estate, Heritage, Sonoma County',
    description: '',
    price: '61',
    vintage: '2016',
    inventory_id: '7859',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Meritage & Proprietary Blends',
    action: ''
  },
  {
    name: 'Beringer, Quantum, Napa Valley ',
    description: '',
    price: '77',
    vintage: '2017',
    inventory_id: '7867',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Meritage & Proprietary Blends',
    action: ''
  },
  {
    name: 'Opus One, Napa Valley',
    description: '',
    price: '551',
    vintage: '2015',
    inventory_id: '7870',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Meritage & Proprietary Blends',
    action: ''
  },
  {
    name: 'The Prisoner, Napa Valley',
    description: '',
    price: '83',
    vintage: '2021',
    inventory_id: '7875',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Meritage & Proprietary Blends',
    action: ''
  },
  {
    name: 'DAOU, The Pessimist, Red Blend, Paso Robles',
    description: '',
    price: '52',
    vintage: '2021',
    inventory_id: '7876',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Meritage & Proprietary Blends',
    action: ''
  },
  {
    name: 'Bodyguard by DAOU, Red Blend, Paso Robles',
    description: '',
    price: '104',
    vintage: '2021',
    inventory_id: '7890',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Meritage & Proprietary Blends',
    action: ''
  },
  {
    name: 'St. Francis, Old Vines, Zinfandel, Sonoma County',
    description: '',
    price: '72',
    vintage: '2018',
    inventory_id: '7901',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Other Reds',
    action: ''
  },
  {
    name: 'Saldo, Zinfandel, California',
    description: '',
    price: '73',
    vintage: 'NV',
    inventory_id: '7911',
    category: 'Red Wine',
    section: 'United States',
    sub_section: 'Other Reds',
    action: ''
  },
  {
    name: 'Santa Rita, Medalla Real, Maipo Valley',
    description: '',
    price: '54',
    vintage: '2018',
    inventory_id: '8102',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Los Vascos, Gran Reserva, Colchagua Valley',
    description: '',
    price: '60',
    vintage: '2019',
    inventory_id: '8103',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Montes, Alpha, Colchagua Valley',
    description: '',
    price: '60',
    vintage: '2018',
    inventory_id: '8108',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Santa Carolina, Reserva de Familia, Maipo Valley',
    description: '',
    price: '69',
    vintage: '2018',
    inventory_id: '8111',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Los Vascos, Le Dix, Colchagua Valley',
    description: '',
    price: '140',
    vintage: '2017',
    inventory_id: '8120',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Cabernet Sauvignon',
    action: ''
  },
  {
    name: 'Montes, Alpha, Colchagua Valley',
    description: '',
    price: '55',
    vintage: '2016',
    inventory_id: '8141',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Merlot',
    action: ''
  },
  {
    name: 'Santa Carolina, Colchagua Valley',
    description: '',
    price: '52',
    vintage: '2016',
    inventory_id: '8142',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Merlot',
    action: ''
  },
  {
    name: 'Montes, Twins, Colchagua Valley',
    description: '',
    price: '56',
    vintage: '2016',
    inventory_id: '8212',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Blends',
    action: ''
  },
  {
    name: 'Santa Carolina, Assemblage, Central Valley',
    description: '',
    price: '54',
    vintage: '2018',
    inventory_id: '8214',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Blends',
    action: ''
  },
  {
    name: 'Santa Carolina, VSC, Cachapoal Valley',
    description: '',
    price: '72',
    vintage: '2015',
    inventory_id: '8220',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Blends',
    action: ''
  },
  {
    name: 'Santa Rita, Triple C, Maipo Valley',
    description: '',
    price: '103',
    vintage: '2017',
    inventory_id: '8226',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Blends',
    action: ''
  },
  {
    name: 'Montes, Alpha, Aconcagua Coast',
    description: '',
    price: '51',
    vintage: '2018',
    inventory_id: '8266',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Pinot Noir',
    action: ''
  },
  {
    name: 'Viña Casa Blanca, Nimbus, Casa Blanca Valley',
    description: '',
    price: '56',
    vintage: '2019',
    inventory_id: '8268',
    category: 'Red Wine',
    section: 'Chile',
    sub_section: 'Pinot Noir',
    action: ''
  },
  {
    name: 'Texas de Brazil, Cabernet Sauvignon, Private Label, Uco Valley',
    description: '',
    price: '48',
    vintage: '2021',
    inventory_id: '8100',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Texas de Brazil, Merlot, Private Label, Uco Valley',
    description: '',
    price: '44',
    vintage: '2021',
    inventory_id: '8140',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Texas de Brazil, Red Blend, Private Label, San Juan',
    description: '',
    price: '48',
    vintage: '2021',
    inventory_id: '8200',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Texas de Brazil, Pinot Noir, Private Label, Uco Valley',
    description: '',
    price: '44',
    vintage: '2021',
    inventory_id: '8264',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Portillo by Bodegas Salentein, Malbec, Uco Valley, Mendoza',
    description: '',
    price: '44',
    vintage: '2017',
    inventory_id: '8002',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Trapiche, Broquel, Malbec, Mendoza',
    description: '',
    price: '52',
    vintage: '2020',
    inventory_id: '8005',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Bodegas Salentein, Reserve, Malbec, Uco Valley, Mendoza',
    description: '',
    price: '63',
    vintage: '2019',
    inventory_id: '8012',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Alta Vista, Terroir Selection, Malbec, Mendoza ',
    description: '',
    price: '83',
    vintage: '2018',
    inventory_id: '8016',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Bodegas Salentein, Reserve, Cabernet Sauvignon, Mendoza',
    description: '',
    price: '65',
    vintage: '2017',
    inventory_id: '8034',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Trapiche, Medalla, Cabernet Sauvignon, Mendoza',
    description: '',
    price: '65',
    vintage: '2015',
    inventory_id: '8038',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Numina by Bodegas Salentein, Red Blend, Uco Valley, Mendoza',
    description: '',
    price: '107',
    vintage: '2019',
    inventory_id: '8046',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Amancaya, Gran Reserva, Red Blend, Mendoza ',
    description: '',
    price: '50',
    vintage: '2019',
    inventory_id: '8047',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Nieto Senetiner, Red Blend, Mendoza',
    description: '',
    price: '57',
    vintage: '2019',
    inventory_id: '8048',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Bodega Norton, Privada, Lujan de Cuyo, Mendoza ',
    description: '',
    price: '64',
    vintage: '2016',
    inventory_id: '8050',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Argentina',
    action: ''
  },
  {
    name: 'Louis Jadot, Beaujolais Villages, Burgundy',
    description: '',
    price: '53',
    vintage: '2021',
    inventory_id: '8702',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'France',
    action: ''
  },
  {
    name: 'Château La Nerth, Les Cassagnes , Côtes-du-Rhône, Rhône Valley',
    description: '',
    price: '45',
    vintage: '2020',
    inventory_id: '8710',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'France',
    action: ''
  },
  {
    name: 'Jean-Luc Colombo, Châteauneuf du Pape, Rhône Valley',
    description: '',
    price: '104',
    vintage: '2019',
    inventory_id: '8613',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'France',
    action: ''
  },
  {
    name: 'Legende by Domaines Barons de Rothschild, Pauillac, Bordeaux',
    description: '',
    price: '117',
    vintage: '2015',
    inventory_id: '8612',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'France',
    action: ''
  },
  {
    name: 'Mazzei, Badiola, Toscana, IGT, Tuscany ',
    description: '',
    price: '46',
    vintage: '2019',
    inventory_id: '8499',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Italy',
    action: ''
  },
  {
    name: 'Ruffino, Aziano, Chianti Classico, Tuscany',
    description: '',
    price: '53',
    vintage: '2016',
    inventory_id: '8502',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Italy',
    action: ''
  },
  {
    name: 'Tenuta San Guido, Guidalberto, Baby Sassicaia, Tuscany',
    description: '',
    price: '150',
    vintage: '2014',
    inventory_id: '8530',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Italy',
    action: ''
  },
  {
    name: 'Tenuta San Guido, Guidalberto, Sassicaia, Tuscany',
    description: '',
    price: '504',
    vintage: '2015',
    inventory_id: '8532',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Italy',
    action: ''
  },
  {
    name: 'Antinori, Tignanello, Super Tuscan, Tuscany',
    description: '',
    price: '235',
    vintage: '2019',
    inventory_id: '8538',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Italy',
    action: ''
  },
  {
    name: 'Masi, Campofiorin, Rosso del Veronese, IGT, Veneto',
    description: '',
    price: '65',
    vintage: '2018',
    inventory_id: '8578',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Italy',
    action: ''
  },
  {
    name: 'Masi, Costasera, Amarone, Veneto',
    description: '',
    price: '156',
    vintage: '2016',
    inventory_id: '8580',
    category: 'Red Wine',
    section: 'International',
    sub_section: 'Italy',
    action: ''
  },
  {
    name: 'Stag’s Leap Wine Cellars, Artemis, Cabernet Sauvignon, Napa Valley',
    description: 'Magnum (1.5 Liters)',
    price: '281',
    vintage: '2015',
    inventory_id: '8906',
    category: 'Large Format Bottles',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'FontanaFredda, Serralunga, Barolo, Piedmont, Italy',
    description: 'Magnum (1.5 Liters)',
    price: '156',
    vintage: '2015',
    inventory_id: '8818',
    category: 'Large Format Bottles',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'Rocca della Macie, Chianti Classico, Tuscany, Italy',
    description: 'Magnum (1.5 Liters)',
    price: '85',
    vintage: '2018',
    inventory_id: '8905',
    category: 'Large Format Bottles',
    section: 'Red',
    sub_section: '',
    action: ''
  },
  {
    name: 'Blanton, Single Barrel',
    description: '(1.5oz, 98-120 cal)',
    price: '22.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'American Whisky',
    sub_section: 'Bourbon',
    action: ''
  },
  {
    name: 'Eagle Rare',
    description: '(1.5oz, 98-120 cal)',
    price: '16',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'American Whisky',
    sub_section: 'Bourbon',
    action: ''
  },
  {
    name: "Maker's Mark, Red Seal 90",
    description: '(1.5oz, 98-120 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'American Whisky',
    sub_section: 'Bourbon',
    action: ''
  },
  {
    name: 'Knob Creek',
    description: '(1.5oz, 98-120 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'American Whisky',
    sub_section: 'Bourbon',
    action: ''
  },
  {
    name: '1792 Small Batch',
    description: '(1.5oz, 98-120 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'American Whisky',
    sub_section: 'Bourbon',
    action: ''
  },
  {
    name: 'Woodford Reserve',
    description: '(1.5oz, 98-120 cal)',
    price: '13.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'American Whisky',
    sub_section: 'Bourbon',
    action: ''
  },
  {
    name: 'Woodford Reserve, Double Oak',
    description: '(1.5oz, 98-120 cal)',
    price: '14.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'American Whisky',
    sub_section: 'Bourbon',
    action: ''
  },
  {
    name: 'Jack Daniels, Black Label',
    description: '(1.5oz, 98-120 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'American Whisky',
    sub_section: 'Tennessee Whisky',
    action: ''
  },
  {
    name: 'Gentleman Jack',
    description: '(1.5oz, 98-120 cal)',
    price: '12',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'American Whisky',
    sub_section: 'Tennessee Whisky',
    action: ''
  },
  {
    name: 'Crown Royal',
    description: '(1.5oz, 96 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Canadian Whisky',
    sub_section: '',
    action: ''
  },
  {
    name: 'Crown Royal, Reserve',
    description: '(1.5oz, 96 cal)',
    price: '12.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Canadian Whisky',
    sub_section: '',
    action: ''
  },
  {
    name: 'Jameson',
    description: '(1.5oz, 104 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Irish Whiskey',
    sub_section: '',
    action: ''
  },
  {
    name: 'Glenlivet, 12yr',
    description: '(1.5oz, 95 cal)',
    price: '13.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Single Malt Scotch',
    sub_section: 'Speyside',
    action: ''
  },
  {
    name: 'The Macallan, 12yr ',
    description: '(1.5oz, 95 cal)',
    price: '17.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Single Malt Scotch',
    sub_section: 'Speyside',
    action: ''
  },
  {
    name: 'The Macallan, 15yr',
    description: '(1.5oz, 95 cal)',
    price: '22.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Single Malt Scotch',
    sub_section: 'Speyside',
    action: ''
  },
  {
    name: 'The Macallan, 18yr',
    description: '(1.5oz, 95 cal)',
    price: '51.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Single Malt Scotch',
    sub_section: 'Speyside',
    action: ''
  },
  {
    name: 'The Macallan, Rare Cask',
    description: '(1.5oz, 95 cal)',
    price: '61.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Single Malt Scotch',
    sub_section: 'Speyside',
    action: ''
  },
  {
    name: 'Glenfiddich, 12yr',
    description: '(1.5oz, 95 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Single Malt Scotch',
    sub_section: 'Highlands',
    action: ''
  },
  {
    name: 'Glenmorangie, 10yr',
    description: '(1.5oz, 95 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Single Malt Scotch',
    sub_section: 'Highlands',
    action: ''
  },
  {
    name: 'Talisker, 10yr',
    description: '(1.5oz, 95 cal)',
    price: '15.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Single Malt Scotch',
    sub_section: 'Islands, The Isle of Skye',
    action: ''
  },
  {
    name: 'Chivas Regal, 12yr',
    description: '(1.5oz, 95 cal)',
    price: '13.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Blended Scotch',
    sub_section: '',
    action: ''
  },
  {
    name: 'Johnnie Walker, Black Label, 12yr',
    description: '(1.5oz, 95 cal)',
    price: '13.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Blended Scotch',
    sub_section: '',
    action: ''
  },
  {
    name: 'Johnnie Walker, Gold Label',
    description: '(1.5oz, 95 cal)',
    price: '24.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Blended Scotch',
    sub_section: '',
    action: ''
  },
  {
    name: 'Johnnie Walker, Platinum',
    description: '(1.5oz, 95 cal)',
    price: '41.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Blended Scotch',
    sub_section: '',
    action: ''
  },
  {
    name: 'Johnnie Walker, Blue Label',
    description: '(1.5oz, 95 cal)',
    price: '56.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Blended Scotch',
    sub_section: '',
    action: ''
  },
  {
    name: 'Myers, Platinum',
    description: '(1.5oz, 76-97 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Rum',
    sub_section: '',
    action: ''
  },
  {
    name: 'Brugal, Añejo',
    description: '(1.5oz, 76-97 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Rum',
    sub_section: '',
    action: ''
  },
  {
    name: 'Brugal, 1888',
    description: '(1.5oz, 76-97 cal)',
    price: '14.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Rum',
    sub_section: '',
    action: ''
  },
  {
    name: 'Captain Morgan',
    description: '(1.5oz, 76-97 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Rum',
    sub_section: '',
    action: ''
  },
  {
    name: 'Malibu Coconut',
    description: '(1.5oz, 76-97 cal)',
    price: '10.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Rum',
    sub_section: '',
    action: ''
  },
  {
    name: 'Sammy’s Beach Bar Kola',
    description: '(1.5oz, 76-97 cal)',
    price: '12',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Rum',
    sub_section: '',
    action: ''
  },
  {
    name: 'Sammy’s Beach Bar Red Head',
    description: '(1.5oz, 76-97 cal)',
    price: '12',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Rum',
    sub_section: '',
    action: ''
  },
  {
    name: 'Sammy’s Beach Bar BLonde',
    description: '(1.5oz, 76-97 cal)',
    price: '12',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Rum',
    sub_section: '',
    action: ''
  },
  {
    name: '1800 Reposado',
    description: '(1.5oz, 104 cal)',
    price: '12.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Tequila',
    sub_section: '',
    action: ''
  },
  {
    name: 'Jose Cuervo Especial, Silver',
    description: '(1.5oz, 104 cal)',
    price: '10',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Tequila',
    sub_section: '',
    action: ''
  },
  {
    name: 'Maestro Dobel, Diamante, Reposado',
    description: '(1.5oz, 104 cal)',
    price: '12.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Tequila',
    sub_section: '',
    action: ''
  },
  {
    name: 'Patron Platinum, Gran Patron, Silver',
    description: '(1.5oz, 104 cal)',
    price: '46.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Tequila',
    sub_section: '',
    action: ''
  },
  {
    name: 'Patron, Añejo',
    description: '(1.5oz, 104 cal)',
    price: '14.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Tequila',
    sub_section: '',
    action: ''
  },
  {
    name: 'Patron, Reposado',
    description: '(1.5oz, 104 cal)',
    price: '14.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Tequila',
    sub_section: '',
    action: ''
  },
  {
    name: 'Patron, Silver',
    description: '(1.5oz, 104 cal)',
    price: '13.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Tequila',
    sub_section: '',
    action: ''
  },
  {
    name: 'Santo, Silver',
    description: '(1.5oz, 104 cal)',
    price: '12.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Tequila',
    sub_section: '',
    action: ''
  },
  {
    name: 'Santo, Reposado',
    description: '(1.5oz, 104 cal)',
    price: '13',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Tequila',
    sub_section: '',
    action: ''
  },
  {
    name: 'Santo, Mezquila',
    description: '(1.5oz, 104 cal)',
    price: '13.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Tequila',
    sub_section: '',
    action: ''
  },
  {
    name: 'Xicala, Mezcal, Joven',
    description: '(1.5oz, 104 cal)',
    price: '12',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Tequila',
    sub_section: '',
    action: ''
  },
  {
    name: 'Absolut',
    description: '(1.5oz, 104-150 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Chopin',
    description: '(1.5oz, 104-150 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Belvedere',
    description: '(1.5oz, 104-150 cal)',
    price: '13.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Grey Goose',
    description: '(1.5oz, 104-150 cal)',
    price: '13.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Ketel One',
    description: '(1.5oz, 104-150 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Wheatley',
    description: '(1.5oz, 104-150 cal)',
    price: '10',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Deep Eddy Lemon',
    description: '(1.5oz, 104-150 cal)',
    price: '10',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Absolut, Citron',
    description: '(1.5oz, 104-150 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Absolut, Mandrin',
    description: '(1.5oz, 104-150 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Absolut, Raspberri',
    description: '(1.5oz, 104-150 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Absolut, Mango',
    description: '(1.5oz, 104-150 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Absolut, Vanilia',
    description: '(1.5oz, 104-150 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: "Tito's",
    description: '(1.5oz, 104-150 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Vodka',
    sub_section: '',
    action: ''
  },
  {
    name: 'Beefeater',
    description: '(1.5oz, 115 cal)',
    price: '10',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Gin',
    sub_section: '',
    action: ''
  },
  {
    name: 'Bombay, Sapphire',
    description: '(1.5oz, 115 cal)',
    price: '11.25',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Gin',
    sub_section: '',
    action: ''
  },
  {
    name: 'Tanqueray, Ten',
    description: '(1.5oz, 115 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Gin',
    sub_section: '',
    action: ''
  },
  {
    name: 'Drumshanbo, Gunpowder',
    description: '(1.5oz, 115 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Gin',
    sub_section: '',
    action: ''
  },
  {
    name: 'Hendrick’s',
    description: '(1.5oz, 115 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Gin',
    sub_section: '',
    action: ''
  },
  {
    name: 'Hennessy, VS',
    description: '(1.5oz, 104 cal)',
    price: '13',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cognac',
    sub_section: '',
    action: ''
  },
  {
    name: 'Hennessy, VSOP',
    description: '(1.5oz, 104 cal)',
    price: '14.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cognac',
    sub_section: '',
    action: ''
  },
  {
    name: 'Hennessy, XO',
    description: '(1.5oz, 104 cal)',
    price: '42.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cognac',
    sub_section: '',
    action: ''
  },
  {
    name: 'Rémy Martin, VSOP',
    description: '(1.5oz, 104 cal)',
    price: '14.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cognac',
    sub_section: '',
    action: ''
  },
  {
    name: 'Rémy Martin, 1738',
    description: '(1.5oz, 104 cal)',
    price: '17.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cognac',
    sub_section: '',
    action: ''
  },
  {
    name: 'Rémy Martin, XO',
    description: '(1.5oz, 104 cal)',
    price: '42.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cognac',
    sub_section: '',
    action: ''
  },
  {
    name: 'Rémy Martin, Louis XIII (.5oz)',
    description: '(0.5oz, 35 cal)',
    price: '116',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cognac',
    sub_section: '',
    action: ''
  },
  {
    name: 'Rémy Martin, Louis XIII (1oz)',
    description: '(1oz, 70 cal)',
    price: '232',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cognac',
    sub_section: '',
    action: ''
  },
  {
    name: 'Rémy Martin, Louis XIII (1.5oz)',
    description: '(1.5oz, 104 cal)',
    price: '348',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cognac',
    sub_section: '',
    action: ''
  },
  {
    name: 'Grappa Alexander Banfi',
    description: '(1.5oz, 128 cal)',
    price: '15.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Grappa',
    sub_section: '',
    action: ''
  },
  {
    name: 'Amaretto Disaronno ',
    description: '(1.5oz, 104-165 cal)',
    price: '10',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Amarula',
    description: '(1.5oz, 104-165 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Bailey’s Original Irish Cream',
    description: '(1.5oz, 104-165 cal)',
    price: '10.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Campari',
    description: '(1.5oz, 104-165 cal)',
    price: '10',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Chambord',
    description: '(1.5oz, 104-165 cal)',
    price: '10.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Cointreau',
    description: '(1.5oz, 104-165 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Drambuie',
    description: '(1.5oz, 104-165 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Frangelico',
    description: '(1.5oz, 104-165 cal)',
    price: '12',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Galliano',
    description: '(1.5oz, 104-165 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Dorda Double Chocolate Liqueur',
    description: '(1.5oz, 104-165 cal)',
    price: '10',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Dorda Coconut Cream Liqueur',
    description: '(1.5oz, 104-165 cal)',
    price: '10',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Dorda Sea Salt Caramel Liqueur',
    description: '(1.5oz, 104-165 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Grand Marnier, Cordon Rouge',
    description: '(1.5oz, 104-165 cal)',
    price: '11.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Grand Marnier, Cuvée du Centenaire',
    description: '(1.5oz, 104-165 cal)',
    price: '37.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Grand Marnier, Cuvée 1880',
    description: '(1.5oz, 104-165 cal)',
    price: '66.5',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Jagermeister',
    description: '(1.5oz, 104-165 cal)',
    price: '10',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Kahlua',
    description: '(1.5oz, 104-165 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Limoncello',
    description: '(1.5oz, 104-165 cal)',
    price: '10',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Licor 43',
    description: '(1.5oz, 104-165 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Midori',
    description: '(1.5oz, 104-165 cal)',
    price: '10',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Sambuca Romana',
    description: '(1.5oz, 104-165 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Sambuca Romana, Black',
    description: '(1.5oz, 104-165 cal)',
    price: '11',
    vintage: '',
    inventory_id: '',
    category: 'Liquors',
    section: 'Cordials',
    sub_section: '',
    action: ''
  },
  {
    name: 'Inniskilln, Cabernet Franc, Ice Wine',
    description: '(375ml Bottle, 1044 cal)',
    price: '',
    vintage: '2006',
    inventory_id: '9120',
    category: 'Dessert Wine & Martinis',
    section: 'Dessert Wine',
    sub_section: 'Canada',
    action: ''
  },
  {
    name: 'Nivole by Michele Chiarlo, Moscato d´Asti',
    description: '(3oz Glass, 348 cal) & (375ml Bottle, 1044 cal)',
    price: '',
    vintage: '2009',
    inventory_id: '9132',
    category: 'Dessert Wine & Martinis',
    section: 'Dessert Wine',
    sub_section: 'Italy',
    action: ''
  },
  {
    name: 'Tropical Cream Dream',
    description:
      'Passion fruit puree, Absolut mango vodka, shaken together with condensed milk and fresh cream with a touch of raspberry syrup on the rim (319 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Dessert Wine & Martinis',
    section: 'Dessert Martinis',
    sub_section: '',
    action: ''
  },
  {
    name: 'Espresso Delight',
    description:
      'Absolut Vanilia vodka with a touch of Baileys Irish cream and a shot of espresso, topped with whipped cream laced with chocolate (274 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Dessert Wine & Martinis',
    section: 'Dessert Martinis',
    sub_section: '',
    action: ''
  },
  {
    name: 'The Ruler of Marula ',
    description:
      'Amarula fruit cream and Absolut Vanilia vodka, shaken over ice with a fresh whipped topping (274 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Dessert Wine & Martinis',
    section: 'Dessert Martinis',
    sub_section: '',
    action: ''
  },
  {
    name: 'Milky Way',
    description:
      'Absolut Vanilia vodka, Godiva white and dark chocolate liqueur, Baileys and a touch of cream, shaken and served in chocolate rimmed glass and finished with a cherry (222 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Dessert Wine & Martinis',
    section: 'Dessert Martinis',
    sub_section: '',
    action: ''
  },
  {
    name: 'Key Lime Pie Martini',
    description:
      'Absolut Vanilia vodka and Licor 43 with a touch of cream and lime juice, served in martini glass with a lime wheel (293 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Dessert Wine & Martinis',
    section: 'Dessert Martinis',
    sub_section: '',
    action: ''
  },
  {
    name: 'Tiramisu Martini',
    description:
      'Kahlua coffee liquor and Godiva chocolate liquor with a touch of Absolut Vanilia vodka, served in martini glass decorated with chocolate syrup (234 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Dessert Wine & Martinis',
    section: 'Dessert Martinis',
    sub_section: '',
    action: ''
  },
  {
    name: 'Lava',
    description:
      'A blend of coffee, caramel, condensed milk and your choice of cachaça, served in a martini glass laced with condensed milk (248 cal)',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Dessert Wine & Martinis',
    section: 'Dessert Martinis',
    sub_section: '',
    action: ''
  },
  {
    name: 'Fonseca, BIN. 27',
    description: '(3oz Glass, 141 cal) & (750ml Bottle, 1175 cal)',
    price: '9.75',
    vintage: '',
    inventory_id: '9202',
    category: 'Fortified Wines',
    section: 'Port',
    sub_section: 'Ruby',
    action: ''
  },

  {
    name: 'Taylor Fladgate',
    description: '(3oz Glass, 141 cal) & (750ml Bottle, 1175 cal)',
    price: '8.5',
    vintage: '',
    inventory_id: '9210',
    category: 'Fortified Wines',
    section: 'Port',
    sub_section: 'Ruby',
    action: ''
  },
  {
    name: 'Taylor Fladgate 10 Year',
    description: '(3oz Glass, 141 cal) & (750ml Bottle, 1175 cal)',
    price: '11',
    vintage: '',
    inventory_id: '9228',
    category: 'Fortified Wines',
    section: 'Port',
    sub_section: 'Tawny',
    action: ''
  },
  {
    name: 'Taylor Fladgate 20 Year',
    description: '(3oz Glass, 141 cal) & (750ml Bottle, 1175 cal)',
    price: '21',
    vintage: '',
    inventory_id: '9230',
    category: 'Fortified Wines',
    section: 'Port',
    sub_section: 'Tawny',
    action: ''
  },
  {
    name: 'Taylor Fladgate 30 Year',
    description: '(3oz Glass, 141 cal) & (750ml Bottle, 1175 cal)',
    price: '31',
    vintage: '',
    inventory_id: '9232',
    category: 'Fortified Wines',
    section: 'Port',
    sub_section: 'Tawny',
    action: ''
  },
  {
    name: 'Taylor Fladgate 40 Year',
    description: '(3oz Glass, 141 cal) & (750ml Bottle, 1175 cal)',
    price: '46',
    vintage: '',
    inventory_id: '9234',
    category: 'Fortified Wines',
    section: 'Port',
    sub_section: 'Tawny',
    action: ''
  },
  {
    name: 'Taylor Fladgate 2003',
    description: '(750ml Bottle, 1175 cal)',
    price: '76',
    vintage: '',
    inventory_id: '9250',
    category: 'Fortified Wines',
    section: 'Port',
    sub_section: 'Late Bottle Vintage',
    action: ''
  },
  {
    name: 'Taylor Fladgate Flight',
    description: '10 Year, 20 Year, 30 Year, 40 Year',
    price: '46',
    vintage: '(188 cal)',
    inventory_id: '',
    category: 'Flights',
    section: '(1 oz. of each)',
    sub_section: '',
    action: ''
  },
  {
    name: 'Styles of Port Flight',
    description:
      'Fonseca BIN. 27, Taylor Fladgate LBV, Taylor Fladgate 10 Year',
    price: '11',
    vintage: '(141 cal)',
    inventory_id: '',
    category: 'Flights',
    section: '(1 oz. of each)',
    sub_section: '',
    action: ''
  },
  {
    name: 'Hennessy Flight',
    description: 'VS, VSOP, XO',
    price: '43',
    vintage: '(208 cal)',
    inventory_id: '',
    category: 'Flights',
    section: '(1 oz. of each)',
    sub_section: '',
    action: ''
  },
  {
    name: 'Patrón Flight',
    description: 'Silver, Añejo, Gran Patrón Platinum',
    price: '46',
    vintage: '(208 cal)',
    inventory_id: '',
    category: 'Flights',
    section: '(1 oz. of each)',
    sub_section: '',
    action: ''
  },
  {
    name: 'Grand Marnier Flight',
    description: 'Cordon Rouge, Cuvée du Centenaire, Cuvée 1880',
    price: '66',
    vintage: '(300 cal)',
    inventory_id: '',
    category: 'Flights',
    section: '(1 oz. of each)',
    sub_section: '',
    action: ''
  },
  {
    name: 'The Macallan Flight',
    description: '12 Year, 15 Year, 18 Year',
    price: '41',
    vintage: '(190 cal)',
    inventory_id: '',
    category: 'Flights',
    section: '(1 oz. of each)',
    sub_section: '',
    action: ''
  },
  {
    name: 'Dorada Flight',
    description: 'Double Chocolate, Sea Salt and Caramel, and Coconut',
    price: '16',
    vintage: '(310 cal)',
    inventory_id: '',
    category: 'Flights',
    section: '(1 oz. of each)',
    sub_section: '',
    action: ''
  },
  {
    name: 'Johnnie Walker Flight',
    description: 'Black, Gold, Blue',
    price: '46',
    vintage: '(190 cal)',
    inventory_id: '',
    category: 'Flights',
    section: '(1 oz. of each)',
    sub_section: '',
    action: ''
  },
  {
    name: 'Chocolate Mousse Cake',
    description:
      'Layers of delectable chocolate cake, sweet chocolate amaretto mousse and white chocolate butterscotch mousse coated with a bittersweet chocolate ganache',
    price: '12',
    vintage: '',
    inventory_id: 'By the slice',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Brazilian Cheesecake',
    description:
      'Creamy cheesecake and a layer of rich fudge atop a graham cracker crust and topped with caramel sauce and chopped nuts',
    price: '12',
    vintage: '',
    inventory_id: 'By the slice',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Carrot Cake',
    description:
      'Three layers of moist carrot cake coated inside and out with a rich cream cheese icing and filled with carrots, coconut, pineapple, and walnuts',
    price: '13',
    vintage: '',
    inventory_id: 'By the slice',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Key Lime Pie',
    description:
      'Florida style key lime pie – a refreshing tart custard atop a graham cracker crust with lime juice laced whipped cream',
    price: '11',
    vintage: '',
    inventory_id: 'By the slice',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Bananas Foster Pie',
    description:
      'Fresh banana pie made of lady finger cake and a layer of fudge atop a graham cracker crust topped with whipped cream and caramel sauce',
    price: '11',
    vintage: '',
    inventory_id: 'By the slice',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Coconut Chess Pie',
    description:
      'A southern style pie topped with fresh toasted coconut flakes - served either on its own or warm with a scoop of vanilla ice cream',
    price: '11',
    vintage: '',
    inventory_id: 'By the slice',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Pecan Pie',
    description:
      'A southern style pie made with glazed pecans atop a rich layer of custard -served by itself or warm with a side of vanilla ice cream',
    price: '11',
    vintage: '',
    inventory_id: 'By the slice',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Flan',
    description:
      'Our very own homemade custard served in a rich pool of golden caramel sauce',
    price: '10',
    vintage: '',
    inventory_id: 'Other Desserts',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Crème brûlée',
    description:
      'A citrus-vanilla custard topped with a contrasting hardened layer of caramelized sugar – made in house',
    price: '10',
    vintage: '',
    inventory_id: 'Other Desserts',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Brazilian Papaya Cream',
    description:
      'Our signature dessert made of a smooth blend of papaya and vanilla ice cream served with a swirl of crème de cassis',
    price: '10',
    vintage: '',
    inventory_id: 'Other Desserts',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Passion Fruit Mousse',
    description:
      'A native fruit to Brazil, our homemade Passion Fruit Mousse is sweet and tangy, balanced with a graham cracker crust, and topped with chocolate shavings.',
    price: '11',
    vintage: '',
    inventory_id: 'Other Desserts',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Vanilla Ice Cream',
    description: 'Rich vanilla bean ice cream',
    price: '5',
    vintage: '',
    inventory_id: 'Other Desserts',
    category: 'Desserts',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Basket of House-Baked Cheese Bread',
    description: '',
    price: '3.5',
    vintage: '',
    inventory_id: '',
    category: 'Bar Appetizers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Antipasto Platter',
    description: '(Artisanal Cheeses, Charcuterie, Olives and Cornichons)',
    price: '14',
    vintage: '',
    inventory_id: '',
    category: 'Bar Appetizers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Bowl of House-Made Lobster Bisque',
    description: '',
    price: '9',
    vintage: '',
    inventory_id: '',
    category: 'Bar Appetizers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Garlic Picanha, Chicken Breast with Bacon and Sausage',
    description: '',
    price: '14',
    vintage: '',
    inventory_id: '',
    category: 'Bar Appetizers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Filet Mignon with Bacon, Garlic Picanha and Chicken Breast with Bacon',
    description: '',
    price: '22',
    vintage: '',
    inventory_id: '',
    category: 'Bar Appetizers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Sarratoga Spring Water',
    description: '',
    price: '8',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Bottled Water',
    sub_section: '',
    action: ''
  },
  {
    name: 'Sarratoga Sparkling Water',
    description: '',
    price: '8',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Bottled Water',
    sub_section: '',
    action: ''
  },
  {
    name: 'Guaraná Brazilian Soda',
    description: '',
    price: '4.75',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Soft Drinks',
    sub_section: '',
    action: ''
  },
  {
    name: 'Brazilian Limade (Glass) - Lime, Strawberry, Passion Fruit, Mango & Coconut',
    description: '',
    price: '4.75',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Soft Drinks',
    sub_section: '',
    action: ''
  },
  {
    name: 'Brazilian Limade (Pitcher) - Lime, Strawberry, Passion Fruit, Mango & Coconut',
    description: '',
    price: '19',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Soft Drinks',
    sub_section: '',
    action: ''
  },
  {
    name: 'Coke',
    description: '',
    price: '4.50',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Soft Drinks',
    sub_section: '',
    action: ''
  },
  {
    name: 'Diet Coke',
    description: '',
    price: '4.50',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Soft Drinks',
    sub_section: '',
    action: ''
  },
  {
    name: 'Sprite',
    description: '',
    price: '4.50',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Soft Drinks',
    sub_section: '',
    action: ''
  },
  {
    name: 'Ginger Ale',
    description: '',
    price: '4.50',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Soft Drinks',
    sub_section: '',
    action: ''
  },
  {
    name: 'Calypso Lemonade - Lemon & Strawberry',
    description: '',
    price: '4.75',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Soft Drinks',
    sub_section: '',
    action: ''
  },
  {
    name: 'Juices - Apple, Orange, Cranberry, Pineapple, Tomato',
    description: '',
    price: '4.25',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Soft Drinks',
    sub_section: '',
    action: ''
  },
  {
    name: 'Brewed Coffee or Decaf',
    description: '',
    price: '4',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Coffee & Tea',
    sub_section: '',
    action: ''
  },
  {
    name: 'Cappuccino',
    description: '',
    price: '5.5',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Coffee & Tea',
    sub_section: '',
    action: ''
  },
  {
    name: 'Espresso',
    description: '',
    price: '5',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Coffee & Tea',
    sub_section: '',
    action: ''
  },
  {
    name: 'Double Espresso',
    description: '',
    price: '5.5',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Coffee & Tea',
    sub_section: '',
    action: ''
  },
  {
    name: 'Brewed Iced Tea',
    description: '',
    price: '4',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Coffee & Tea',
    sub_section: '',
    action: ''
  },
  {
    name: 'Hot Tea - ask server for selection',
    description: '',
    price: '3.5',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Coffee & Tea',
    sub_section: '',
    action: ''
  },
  {
    name: 'Republic of Tea - ask server for selection',
    description: '',
    price: '4.75',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Coffee & Tea',
    sub_section: '',
    action: ''
  },
  {
    name: 'Milk',
    description: '',
    price: '3.75',
    vintage: '',
    inventory_id: '',
    category: 'NA Beverage',
    section: 'Coffee & Tea',
    sub_section: '',
    action: ''
  },
  {
    name: 'Corona',
    description: '',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Beers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Corona Premier',
    description: '',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Beers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Coors Light',
    description: '',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Beers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Miller Light',
    description: '',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Beers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Xingu',
    description: '',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Beers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Palma Louca',
    description: '',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Beers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Stella Artois',
    description: '',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Beers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Modelo',
    description: '',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Beers',
    section: '',
    sub_section: '',
    action: ''
  },
  {
    name: 'Modelo Negro',
    description: '',
    price: '',
    vintage: '',
    inventory_id: '',
    category: 'Beers',
    section: '',
    sub_section: '',
    action: ''
  }
]
