import React from 'react'

import CocktailSection from './CocktailSection'
import GlassBottleSection from './GlassBottleSection'

const DwAndM = (props) => {
  // filters all menu items for by category, then by section
  const dwAndM = props.mainMenuData.filter(
    (item) => item.category === props.category
  )
  const dessertWine = dwAndM.filter((item) => item.section === 'Dessert Wine')
  const martinis = dwAndM.filter((item) => item.section === 'Dessert Martinis')

  const restrictions = props.restrictions && props.restrictions.general

  return (
    <div className='main'>
      <h1 style={{ marginBottom: '0' }}>Dessert Wines</h1>
      <h3
        style={{
          marginTop: '8px',
          marginBottom: '40px',
          padding: '0 20px 0 20px',
          lineHeight: '20px',
          color: '#86090f',
          fontSize: '1.17rem'
        }}
      >
        {props.subtitle}
      </h3>
      <GlassBottleSection title={'Dessert Wine'} wines={dessertWine} />
      <h1 style={{ marginBottom: '0' }}>Dessert Martinis</h1>
      <h3
        style={{
          marginTop: '18px',
          marginBottom: '40px',
          padding: '0px 20px 0 20px',
          lineHeight: '20px',
          color: '#86090f',
          fontSize: '32px'
        }}
      >
        13.5
      </h3>
      <CocktailSection cocktails={martinis} />
      <h4 className='menu-may-vary'>Menu selection may vary by location.</h4>
      <div className='line-break'></div>
      <p className='wine-restrictions'>{restrictions}</p>
    </div>
  )
}

export default DwAndM
