import React from 'react'

import CocktailSection from './CocktailSection'

const Cocktails = (props) => {
  const cocktailsCategory = props.mainMenuData.filter(
    (item) => item.category === 'Cocktails'
  )
  const cocktails = cocktailsCategory.filter(
    (item) => item.section === 'Cocktails'
  )
  const cachaca = cocktailsCategory.filter((item) =>
    item.name === 'Caipirinha' ? null : item.section === 'Cachaca Cocktails'
  )

  const restrictions = props.restrictions && props.restrictions.general
  return (
    <div className='main'>
      <h1 style={{ marginBottom: '10px' }}>Cachaça Cocktails</h1>
      <h3
        className='cachaca-pricing'
        style={{ lineHeight: '25px', fontSize: '1.17rem' }}
      >
        For pricing, ask your server about our cachaça selections!
      </h3>
      <div className='box-menu-item caipirinha'>
        <h3>Caipirinha</h3>
        <p>
          Our signature cocktail is made with fresh fruit and sugar muddled,
          then shaken with your choice of cachaça.
        </p>
        <p>
          <span>Choose one of the following fruit options:</span>
        </p>
        <p>
          Traditional Lime <span>(190 cal)</span>
        </p>
        <p>
          Strawberry <span>(240 cal)</span>
        </p>
        <p>
          Passion Fruit <span>(240 cal)</span>
        </p>
        <p>
          Mango <span>(240 cal)</span>
        </p>
        <p>
          Coconut <span>(250 cal)</span>
        </p>
      </div>
      <img
        className='caipirinhas-pic'
        src='https://s3.amazonaws.com/tdb.online-images-secure/caipirinhas.png'
        alt='Caipirinhas'
        width='100%'
        style={{ marginTop: '1rem' }}
      />
      <CocktailSection title={''} cocktails={cachaca} />
      <CocktailSection title={'Cocktails'} cocktails={cocktails} price={'14'} />
      <h4 className='menu-may-vary'>Menu selection may vary by location.</h4>
      <div className='line-break'></div>
      <p className='wine-restrictions'>{restrictions}</p>
    </div>
  )
}

export default Cocktails
